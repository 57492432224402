import { PageProps, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import UniformDetail from '../../../components/register/uniform-detail'

const RegisterPlayerOrderUniformItemPage = (props: PageProps) => {
  const cartType = 'player'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const uniformId = new URLSearchParams(props.location.search).get('uniformId')

  useEffect(() => {
    if (!uniformId) {
      navigate('/register/player/order-uniform')
    }
  }, [uniformId])

  const onSuccess = () => {
    navigate('/register/player/order-uniform')
  }

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      {uniformId && (
        <UniformDetail
          uniformId={uniformId}
          onSuccess={onSuccess}
          uniformType={cartType}
        />
      )}
    </>
  )
}

export default RegisterPlayerOrderUniformItemPage
